<template>
    <div class="dialog_box">
        <el-dialog title="合同文件" :visible.sync="shows" :close-on-click-modal="false"  :close-on-press-escape="false" @closed="onclosed" >
            <div class="pdf_list">
                <el-table :data="pdf_list" stripe style="width: 100%" header-cell-class-name="el_table_title"    v-loading="loading" height="525" >
                    <el-table-column prop="index"   type="index" label="序号" width="130"></el-table-column>
                    <el-table-column prop="name" label="文件名称" ></el-table-column>
                     <!-- 操作模块   -->
                     <el-table-column label="操作" fixed="right"  width="150">
                        <template slot-scope="scope">
                            <div class="scope_btns">
                                <el-button type="text" @click="handle_see(scope.row)">查看文件</el-button>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-dialog>
    </div>
</template>
<script>


export default {
    name: "Customer_Information_form",
    components:{
    },
    props: {

    },
    data() {
        return {
            loading:false,
            shows: false,
            pdf_list:[],

        };
    },
    methods: {
        onclosed(){
            this.pdf_list=[]

        },

        //打开
        handle_open(item) {
            if(item){
                this.pdf_list=JSON.parse(item.pdf)
            }
            this.shows = true
        },
        //关闭    
        handle_close() {
            this.shows = false

        },
        handle_see(item){
            window.open(item.path)
        },
        handle_link(item){
            
        },
      
         
    },
    computed: {
    },

    watch: {
        
    },


    created() {
    },


};
</script>

<style scoped lang="less">
.dialog_box {
    /deep/.el-dialog {
        width: 960px;
    }

    /deep/.el-dialog__header {
        background: #F2F5FA !important;
    }
    .pdf_list{

    }

    
}
</style>