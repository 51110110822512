<template>
    <div class="page_box">
        <div class="bom_box">
            <div class="bom_box_list">
                <el-table :data="list" stripe style="width: 100%" header-cell-class-name="el_table_title"   v-loading="loading" >
                    <el-table-column prop="created_at" label="合同" width="180">
                        <template slot-scope="scope">
                             <div class="scope_ht_box">
                                <div class="imgs">
                                    <img src="@/assets/img/employee/ht.png" alt="">
                                </div>
                                <div class="text" @click="handleCommand({key: 1, row: scope.row})">合同文件</div>
                             </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="s_time" label="签订时间" ></el-table-column>
                    <el-table-column prop="e_time" label="到期时间" >
                        <template slot-scope="scope">
                             <div class="daoqi_time">
                                <div class="time_text">{{ scope.row.e_time }}</div>
                                <div class="daoqi_texts_colo1" v-if="e_time_tx[scope.$index]==1">即将到期</div>
                                <div class="daoqi_texts_colo2" v-if="e_time_tx[scope.$index]==2">已到期</div>
                             </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="created_at" label="更新时间"></el-table-column>
                    <el-table-column label="操作" fixed="right"  width="150">
                        <template slot-scope="scope">
                            <div class="scope_btns">
                                <el-button type="text" @click="handleCommand({key: 1, row: scope.row})">查看合同</el-button>
                            </div>
                        </template>
                    </el-table-column>
                   
                </el-table>
                <div class="pages">
                    <page :pageson='count' @row="row" @currentPage="currentPage"></page>
                </div>
            </div>
        </div>
        <Contract_management_pdf ref="Contract_management_pdf"></Contract_management_pdf>
    </div>
</template>
<script>
import page from "@/components/page/page.vue"; //分页  统一的
import Contract_management_pdf from "@/components/Contract_management/Contract_management_pdf.vue"; //合同w文件

export default {
    components: {
        page,
        Contract_management_pdf

    },
    data() {
        return {
            loading:false,//加载总
            page_title:'',//页面名称  来之路由
            check_list:[],//选中的
            //提交数据
            forms: {
                keyword: '',
                userId:'',
            },
            list: [
            ],
            pages:{
                page:1,
                limit:10,
            },
            count:0,
            item_row:{},

        };
    },

    methods: {
        //接受条数
        currentPage(page) {
            console.log(page)
            this.pages.page = page
            this.get_list()
        },
        // 接收条数
        row(row){
            console.log(row)
            this.pages.limit = row
            this.get_list()
        },
        //选择
        handle_list_change(e){
            console.log('多选',e)
            this.check_list=e
        },
        //搜素
        handle_search(){
            this.pages.page=1
            this.get_list()
        },
      
        //统一的列表接口
        get_list(){
            console.log(this.forms)
            this.loading=true
            this.$api("getUserContractList", {
                // mobile:this.mobile
                ...this.forms,
                ...this.pages,
            }, "get").then((res) => {
                this.loading=false
                if (res.code ==200) {
                    this.count=res.data.count
                    this.list=res.data.list
                }else{
                    alertErr(res.msg)
                }
            });
        },
        //操作回调
        handleCommand(e) {
            // console.log(e)
            this.item_row = e.row
            let i = e.key
            switch (i) {
                //查看合同
                case 1:
                    this.handle_ckht()
                    break;
            }
        },
        //查看合同
        handle_ckht(){
            this.$refs.Contract_management_pdf.handle_open(this.item_row)
        },
    },
    computed: {
        e_time_tx(){
            let lists=[]
            let date1=Date.parse(new Date()) //当前时间

            this.list.forEach((e)=>{
                let i=0
                let date2=Date.parse(e.e_time+" 23:59:59") //到期时间
                if(date2-date1<(86400000*2)&&(date2-date1>0)){
                    i=1
                }else if(date1>date2){
                    i=2
                }
                // if(date1<date2){
                //     i=1
                // }else if(date2-date1>86400000){
                //     i=2
                // }
                lists.push(i)
            })
            console.log('计算',lists)
            return lists
        }
    },
    watch: {
    },
    created() {
        this.page_title=this.$route.meta.title
        // this.forms.keyword=this.$route.query.name
        this.forms.userId=this.$route.query.id

        this.get_list()
    },
    mounted() {

    }


};
</script>

<style scoped lang="less">
.page_box {
    height: 100%;

    .bom_box {
        background: #FFFFFF;
        .bom_box_list {
            // width: 1640px;
            // height: 670px;
            padding: 20px 20px 32px 20px;
            .pages{
                padding-top: 30px;
            }
        }

    }
}
</style>